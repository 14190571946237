var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "login" }
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4"
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/logo.png"),
                            alt: "login"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
                      },
                      [
                        _c(
                          "vx-card",
                          {
                            staticClass: "px-3 mt-8",
                            attrs: { "no-radius": "", "no-shadow": "" }
                          },
                          [
                            _c("div", { staticClass: "vx-card__title mb-7" }, [
                              _c("h4", { staticClass: "mb-7" }, [
                                _vm._v(_vm._s(_vm.$t("auth.login.title")))
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("auth.login.welcome")))
                              ])
                            ]),
                            _c("login-user-token")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }