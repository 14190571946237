<template>
  <div>
    <form @submit.prevent="loginUserToken">
      <vs-input
          v-validate="'required|min:3'"
          data-vv-validate-on="blur"
          name="email"
          icon-no-border
          icon="icon icon-user"
          icon-pack="feather"
          :label-placeholder="$t('auth.login.input.username')"
          v-model="email"
          class="w-full mt-10"/>
      <span class="text-sm text-danger">{{ errors.first('email') }}</span>

      <vs-input
          data-vv-validate-on="blur"
          v-validate="'required'"
          type="password"
          name="password"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :label-placeholder="$t('auth.login.input.password')"
          v-model="password"
          class="w-full mt-10" />
      <span class="text-sm text-danger">{{ errors.first('password') }}</span>

      <div class="flex flex-wrap justify-between mt-5 mb-7">
          <router-link :to="`/${this.$route.params.language}/password-forget`">{{ $t('auth.login.forgot_password') }}</router-link>
      </div>
      <div class="flex flex-wrap justify-between mb-8">
        <vs-button :disabled="!validateForm" @click="loginUserToken">{{ $t('auth.login.title') }}</vs-button>
        <input type="submit" class="hidden"/>
      </div>
    </form>
  </div>
</template>

<script>

import router from '@/router'
import AuthServices from '@/modules/Auth/Services/AuthServices.js'

export default {
  data () {
    return {
      email: process.env.VUE_APP_DEFAULT_USER,
      password: process.env.VUE_APP_DEFAULT_PASSWORD
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  methods: {
    async userAlreadyLoggedIn () {
      const loggedIn = await AuthServices.loggedIn();

      if (loggedIn) {
        // The user is already logged in. Go to home screen
        router.push(router.currentRoute.query.to || '/')
        return true
      }
      return false
    },
    async loginUserToken () {

      if (await this.userAlreadyLoggedIn()) return

      // Loading
      this.$vs.loading()

      const payload = {
        userDetails: {
          email: this.email,
          password: this.password
        }
      }
      this.$store.dispatch('auth/loginUserToken', payload)
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position:'top-right'
          })
        })
    }
  }
}

</script>

